import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location, Site } from '../../../../../global';
import IconLayer from '../../../components/map/layers/IconLayer';
import Layers from '../../../components/map/layers/Layers';
import VisitLayer from '../../../components/map/layers/VisitLayer';
import Map from '../../../components/map/Map';
import storeImage from '../../../assets/icons/store-solid-white.svg';
import { RegisterOptions, useForm } from 'react-hook-form';
import Button from '../../../components/ui/Button';
import Select from '../../../components/forms/Select';
import { updateSite } from '../../../lib/db/sites';

type SiteVisitStep0Props = {
  currentStep: number;
  location: Location;
  site: Site;
};

type AddSiteTypeForm = {
  type: string;
};

const SITE_TYPE = [
  {
    key: 'blockmaker',
    label: 'Blockmaker',
    value: 'blockmaker',
  },
  {
    key: 'site',
    label: 'Site',
    value: 'site',
  },
];

const SiteVisitStep0 = ({
  currentStep,
  location,
  site,
}: SiteVisitStep0Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AddSiteTypeForm>();

  const typeOptions: RegisterOptions = {
    required: 'Please select the site type!',
  };

  const submitHandler = (data) => {  
      updateSite(site.id, { type: data.type });
    };


  if (currentStep !== 0) return null;
  return (
    <>
      <div className="grid grid-cols-9 col-span-9 w-full ">
        <div className="grid grid-cols-9 col-span-9 content-center ">
          <hr className={'col-span-9'} />
          <p className={'col-span-4 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Leads Status
          </p>
          <p className={'col-span-2 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Count
          </p>
          <p className={'col-span-3 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Volume
          </p>
          <div className={'grid grid-cols-9 col-span-9'}>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Leads pending:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {site && site.leadsAmountPending}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {site && site.pendingVolume ? site.pendingVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Leads accepted:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {site && site.leadsAmountAccepted}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {site && site.acceptedVolume ? site.acceptedVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>
              Orders fulfilled:
            </p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {site && site.leadsAmountFulfilled}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {site && site.fulfilledVolume ? site.fulfilledVolume : 0}
            </p>
          </div>
          <hr className={'col-span-9'} />
          <p className={'col-span-9 pl-4 text-left mt-2 mb-2 font-semibold'}>
            Location
          </p>
          {site?.location && (
            <p className="col-span-9 pl-4 text-left mb-2">
              <FontAwesomeIcon icon={faMapMarked} />{' '}
              <a
                href={`geo:${site?.location?.lat},${site?.location?.lng}?q=${
                  site?.location?.lat
                },${site?.location?.lng}(${site && site.name})`}
              >
                Open in Google Maps
              </a>
            </p>
          )}
          <p className={'col-span-9 pl-4 text-left mt-2 mb-2 font-semibold'}>
            Type
          </p>
          {(site?.type === 'blockmaker' || site?.type === 'site') && (
            <p className="col-span-9 pl-4 text-left mb-2">
              {String(site?.type).charAt(0).toUpperCase() + String(site?.type).slice(1)}
            </p>
          )}
          {!(site?.type === 'blockmaker' || site?.type === 'site') && (
            <>
              <p className={'col-span-9 text-left mt-2 mb-2 font-semibold'}>
                To check in, please provide the site type.
              </p>
              <form
                name="add-address-outlet-form"
                onSubmit={(event) => event.preventDefault()}
                className="col-span-full flex flex-col"
              >
                <label htmlFor="siteType">Site Type</label>
                <Select
                  register={register}
                  name="type"
                  items={SITE_TYPE}
                  placeholder="Select site type..."
                  error={errors.type}
                  options={typeOptions}
                  className="col-span-12"
                />
                <Button
                  onClick={handleSubmit(submitHandler)}
                  text="Add site type"
                  color="green"
                  className="mt-4 "
                />
              </form>
            </>
          )}
          {site?.location && (
            <div className="col-span-full h-56 flex mt-4">
              <Map center={site.location} zoom={16} className="w-full h-56">
                <Layers>
                  {location ? (
                    <VisitLayer
                      visitLocation={site.location}
                      userLocation={location}
                      icon={storeImage}
                      name={'Site'}
                    />
                  ) : (
                    <IconLayer
                      location={site.location}
                      icon={storeImage}
                      name={'Site'}
                    />
                  )}
                </Layers>
              </Map>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SiteVisitStep0;
