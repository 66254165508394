import {
  faAngleLeft,
  faAngleRight,
  faExclamationTriangle,
  faSpinner,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Loader from '../../../components/ui/Loader';
import Content from '../../../components/layout/Content';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import { Timestamp } from 'firebase/firestore';
import { useUser } from '../../../lib/hooks/use-user';
import dayjs from 'dayjs';
import { getDistance } from 'geolib';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteVisit } from '../../../lib/hooks/use-siteVisits';
import SiteVisitStep0 from './SiteVisitStep0';
import SiteVisitStep1 from './SiteVisitStep1';
import SiteVisitStep2 from './SiteVisitStep2';
import SiteVisitStep3 from './SiteVisitStep3';
import SiteVisitStep4 from './SiteVisitStep4';
import useInterval from '../../../lib/utils/useInterval';
import ButtonBottomRight from '../../../components/ui/ButtonBottomRight';
import numeral from 'numeral';
import { useGeolocation } from '../../../lib/utils/useGeolocation';
import SiteVisitProductTypes from './SiteVisitProductTypes';
import SiteVisitStakeholderEvent from './SiteVisitStakeholderEvent';
import SiteVisitStepBlockmakerConsumption from './SiteVisitStepBlockmakerConsumption';
import SiteVisitStepSitesConsumption from './SiteVisitStepSitesConsumption';

type VisitParams = 'id';

interface CustomHTMDIVElement extends HTMLDivElement {
  saveStep1?: Function;
  saveProductTypes?: Function;
  saveStakeholderEvent?: Function;
  saveBlockmakerConsumption?: Function;
  saveSiteConsumption?: Function;
}

const SiteVisit = () => {
  const RANGE = 200;
  const MIN_ACCURACY = 100;

  const { id } = useParams<VisitParams>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [visitStep, setVisitStep] = useState(0);
  const [showAlertLocation, setShowAlertLocation] = useState<boolean>(false);
  const [showAlertSite, setShowAlertSite] = useState<boolean>(false);
  const [showAlertVisit, setShowAlertVisit] = useState<boolean>(false);
  const [step1, setStep1] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [stakeholderEvent, setStakeholderEvent] = useState(null);
  const [leadPlaced, setLeadPlaced] = useState(false);
  const visitStep1Ref = useRef<CustomHTMDIVElement>(null);
  const visitStep2Ref = useRef<CustomHTMDIVElement>(null);
  const visitProductTypesRef = useRef<CustomHTMDIVElement>(null);
  const visitStakeholderEventRef = useRef<CustomHTMDIVElement>(null);
  const blockmakerConsumptionEventRef = useRef<CustomHTMDIVElement>(null);
  const siteConsumptionEventRef = useRef<CustomHTMDIVElement>(null);
  const [startedAt, setStartedAt] = useState(null);
  const [visitId, setVisitId] = useState('');
  const [step1IsDirty, setStep1IsDirty] = useState<boolean>(false);
  const [step1IsValid, setStep1IsValid] = useState<boolean>(false);
  const [productTypesIsDirty, setProductTypesIsDirty] = useState<boolean>(false);
  const [productTypesIsValid, setProductTypesIsValid] = useState<boolean>(false);
  const [stakeholderEventIsDirty, setStakeholderEventIsDirty] = useState<boolean>(false);
  const [stakeholderEventIsValid, setStakeholderEventIsValid] = useState<boolean>(false);
  const [blockmakerConsumptionIsDirty, setBlockmakerConsumptionIsDirty] = useState<boolean>(false);
  const [blockmakerConsumptionIsValid, setBlockmakerConsumptionIsValid] = useState<boolean>(false);
  const [blockmakerConsumptionData, setBlockmakerConsumptionData] = useState(null);
  const [siteConsumptionIsDirty, setSiteConsumptionIsDirty] = useState<boolean>(false);
  const [siteConsumptionIsValid, setSiteConsumptionIsValid] = useState<boolean>(false);
  const [siteConsumptionData, setSiteConsumptionData] = useState(null);


  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { site, loading: siteLoading, error: siteError } = useSite(id);
  const {
    visit,
    loading: visitLoading,
    error: visitError,
    add,
  } = useSiteVisit(visitId);

  const finalStep = 6;

  useEffect(() => {
    if (id && site) {
      setVisitId(id);
      setVisitStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const [showReloadButton, setShowReloadButton] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(60);
  const {
    positionError,
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocation({
    userDecisionTimeout: 10000,
    watchPosition: true,
  });

  const locationErrorMessage = useMemo(() => {
    switch (positionError?.code) {
      case 1: // PERMISSION_DENIED
        return 'Please enable location services following the user manual: https://www.mcomtech.ch/guide';
      case 2: // POSITION_UNAVAILABLE
        return 'Location services are unavailable. Please try again later.';
      case 3: // TIMEOUT
        return 'Location services timed out. Please try again later.';
      case undefined:
        return undefined;
      default:
        return 'Unknown error';
    }
  }, [positionError]);

  const distance = useMemo(() => {
    if (coords && site?.location) {
      return getDistance(coords, site.location);
    }
    return undefined;
  }, [coords, site]);

  const distanceErrorMessage = useMemo(() => {
    if (distance > RANGE) {
      const distanceString =
        distance > 1000
          ? numeral(distance).divide(1000).format('0.0') + ' kilometers'
          : distance + ' meters';
      setShowReloadButton(true);
      return `You are ${distanceString} away from the outlet. You have to be within ${RANGE} meters to check in.`;
    }
    return undefined;
  }, [distance, RANGE]);

  const countDownDelay = useMemo(
    () => (countdown === 0 ? null : 1000),
    [countdown]
  );

  const waitingForAccuracy = useMemo(() => {
    if (
      countdown === 0 ||
      !isGeolocationAvailable ||
      !isGeolocationEnabled ||
      positionError
    ) {
      return false;
    }
    return !coords || coords.accuracy > MIN_ACCURACY;
  }, [
    coords,
    MIN_ACCURACY,
    countdown,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  ]);

  useInterval(() => {
    setCountdown(countdown - 1);
  }, countDownDelay);

  useEffect(() => {
    setShowLoader(visitLoading || siteLoading);
  }, [siteLoading, visitLoading]);

  useEffect(() => {
    if (visitError) {
      setShowAlertVisit(true);
    }
  }, [visitError]);

  useEffect(() => {
    if (siteError) {
      setShowAlertSite(true);
    }
  }, [siteError]);

  const handleNext = () => {
    if (visitStep !== finalStep) {
      setVisitStep(visitStep + 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current) {
        visitStep1Ref.current.saveStep1();
      }
    }

    if (visitStep === 2) {
      if (visitProductTypesRef && visitProductTypesRef.current) {
        visitProductTypesRef.current.saveProductTypes();
      }
    }

    if (visitStep === 3) {
      if (visitStakeholderEventRef && visitStakeholderEventRef.current) {
        visitStakeholderEventRef.current.saveStakeholderEvent();
      }
    }

    if (visitStep === 4) {
      if (site?.type === 'site' && siteConsumptionEventRef && siteConsumptionEventRef.current && !visit) {
        siteConsumptionEventRef.current.saveSiteConsumption();
      }

      if (site?.type === 'blockmaker' && blockmakerConsumptionEventRef && blockmakerConsumptionEventRef.current && !visit) {
        blockmakerConsumptionEventRef.current.saveBlockmakerConsumption();
      }
    }
  };

  const handlePrevious = () => {
    if (visitStep !== 0) {
      setVisitStep(visitStep - 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current && !visit) {
        visitStep1Ref.current.saveStep1();
      }
    }

    if (visitStep === 2) {
      if (visitProductTypesRef && visitProductTypesRef.current && !visit) {
        visitProductTypesRef.current.saveProductTypes();
      }
    }

    if (visitStep === 3) {
      if (visitStakeholderEventRef && visitStakeholderEventRef.current) {
        visitStakeholderEventRef.current.saveStakeholderEvent();
      }
    }

    if (visitStep === 4) {
      if (site?.type === 'site' && siteConsumptionEventRef && siteConsumptionEventRef.current && !visit) {
        siteConsumptionEventRef.current.saveSiteConsumption();
      }

      if (site?.type === 'blockmaker' && blockmakerConsumptionEventRef && blockmakerConsumptionEventRef.current && !visit) {
        blockmakerConsumptionEventRef.current.saveBlockmakerConsumption();
      }
    }
  };

  const visitStep1Props = {
    setStep1: setStep1,
    step1: step1,
  };

  const visitProductTypesProps = {
    setProductTypes: setProductTypes,
    productTypes: productTypes,
  };

  const visitStakeholderEventProps = {
    setStakeholderEvent: setStakeholderEvent,
    stakeholderEvent: stakeholderEvent,
  };

  const blockmakerConsumptionEventProps = {
    setBlockmakerConsumptionData: setBlockmakerConsumptionData,
    blockmakerConsumptionData: blockmakerConsumptionData,
  };

  const siteConsumptionEventProps = {
    setSiteConsumptionData: setSiteConsumptionData,
    siteConsumptionData: siteConsumptionData,
  };



  const addVisit = async () => {
    await add({
      siteVisitBasics: step1,
      productTypes: productTypes ?? null,
      blockmakerConsumptionData: blockmakerConsumptionData ?? null,
      siteConsumptionData: siteConsumptionData ?? null,
      // stakeholderEvent,
      distance: distance ?? null,
      location: coords,
      leadPlaced: leadPlaced,
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
      },
      sop: {
        email: user.sop.email,
        id: user.sop.id,
        name: user.sop.name,
        phone: user.sop.phone,
      },
      createdAt: Timestamp.now(),
      site: site,
      startedAt: startedAt,
      finishedAt: Timestamp.now(),
      day: dayjs().date(),
      month: dayjs().month() + 1,
      year: dayjs().year(),
    });

    navigate('/siteVisits');
  };

  useEffect(() => {
    if (positionError) {
      setShowAlertLocation(true);
    }
  }, [positionError]);

  const reload = () => {
    setCountdown(60);
  };

  const checkIn = () => {
    setStartedAt(Timestamp.now());
    setShowReloadButton(false);
    handleNext();
  };

  useEffect(() =>{
    console.log(visitStep, step1IsDirty, step1IsValid)
  }, [visitStep, step1IsDirty, step1IsValid]);

  return (
    <Content>
      <Loader show={showLoader} />
      {showReloadButton && (
        <ButtonBottomRight
          onClick={reload}
          blocked={false}
          color="red"
          arialabel="reload"
          icon={faSync}
        />
      )}
      <Alert
        message={visitError && visitError.message}
        open={showAlertVisit}
        setOpen={(open) => setShowAlertVisit(open)}
        title="Error"
      />
      <Alert
        message={siteError && siteError.message}
        open={showAlertSite}
        setOpen={(open) => setShowAlertSite(open)}
        title="Error"
      />
      <Alert
        message={positionError && locationErrorMessage}
        open={showAlertLocation}
        setOpen={(open) => setShowAlertLocation(open)}
        title="Location Error"
      />
      {visitLoading || site ? (
        <Card>
          <div className="grid grid-cols-12">
            {visitStep === 0 && (
              <Back to="/siteVisits" className=" col-span-3" />
            )}
            <div className="grid grid-cols-9 col-span-12 gap-2">
              <div className="grid grid-rows-2 col-span-9 text-center">
                <h2 className="font-bold row-span-1 -mt-4">
                  {site && site.name}
                </h2>
              </div>
              {!visit && (
                <SiteVisitStep0
                  currentStep={visitStep}
                  location={coords}
                  site={site}
                />
              )}
              {visitStep === 1 && (
                <SiteVisitStep1
                  site={site}
                  ref={visitStep1Ref}
                  {...visitStep1Props}
                  editable={visit ? true : false}
                  setStep1IsDirty={setStep1IsDirty}
                  setStep1IsValid={setStep1IsValid}
                />
              )}
              {/* 
              visitStep === 2 && (
                <SiteVisitStep2
                  ref={visitStep2Ref}
                  {...visitStep2Props}
                  editable={visit ? true : false}
                  setStep2IsValid={setStep2IsValid}
                />
              ) 
              */}
               {visitStep === 2 && (
                <SiteVisitProductTypes
                  site={site}
                  ref={visitProductTypesRef}
                  {...visitProductTypesProps}
                  editable={visit ? true : false}
                  setProductTypesIsValid={setProductTypesIsValid}
                  setProductTypesIsDirty={setProductTypesIsDirty}
                />
              )}
             {
             visitStep === 3 && (
                <SiteVisitStakeholderEvent
                  site={site}
                  ref={visitStakeholderEventRef}
                  {...visitStakeholderEventProps}
                  editable={visit ? true : false}
                  setStakeholderEventIsValid={setStakeholderEventIsValid}
                  setStakeholderEventIsDirty={setStakeholderEventIsDirty}
                />
              )
              }
              {visitStep === 4 && site?.type === 'blockmaker' && (
                <SiteVisitStepBlockmakerConsumption
                  ref={blockmakerConsumptionEventRef}
                  {...blockmakerConsumptionEventProps}
                  editable={visit ? true : false}
                  setBlockmakerConsumptionIsValid={setBlockmakerConsumptionIsValid}
                  setBlockmakerConsumptionDirty={setBlockmakerConsumptionIsDirty}
                />
              )}
              {visitStep === 4 && site?.type === 'site' && (
                <SiteVisitStepSitesConsumption
                  ref={siteConsumptionEventRef}
                  {...siteConsumptionEventProps}
                  editable={visit ? true : false}
                  setSiteConsumptionIsValid={setSiteConsumptionIsValid}
                  setSiteConsumptionIsDirty={setSiteConsumptionIsDirty}
                />
              )}
              {visitStep === 5 && (
              <SiteVisitStep3
                currentStep={visitStep}
                site={site}
                setLeadPlaced={setLeadPlaced}
              />
               )}
              <SiteVisitStep4
                currentStep={visitStep}
                changeStep={(step) => setVisitStep(step)}
                step1Done={step1 ? true : false}
                consumption={site?.type === 'site' ? siteConsumptionData ? true : false : blockmakerConsumptionData ? true : false}
                productTypes={true}
                step3Done={leadPlaced}
              />
              {visitStep === 0 &&
                (site?.location || site?.changes?.location) && (
                  <>
                    {waitingForAccuracy && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="animate-spin h-5 w-5 mr-3"
                        ></FontAwesomeIcon>
                        <p>
                          Waiting for device location...{countdown} seconds
                          left.
                        </p>
                      </div>
                    )}
                    {!waitingForAccuracy && distanceErrorMessage && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center text-red-600 px-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-3 mt-1"
                        ></FontAwesomeIcon>
                        <p>{distanceErrorMessage}</p>
                      </div>
                    )}
                    <Button
                      buttonDisabled={
                        //TODO: change this:
                        waitingForAccuracy ||
                        !!positionError ||
                        !!distanceErrorMessage ||
                        !!!(site.type === 'blockmaker' || site.type === 'site')
                      }
                      className="col-span-7 col-start-2"
                      onClick={checkIn}
                      text={'Click to Check-In'}
                    />
                  </>
                )}
              {visitStep === finalStep && (
                <Button
                  buttonDisabled={
                    !(
                      (step1 ? true : false)
                    )
                  }
                  className="col-span-7 col-start-2"
                  onClick={() => addVisit()}
                  text={'Click to Check-Out'}
                />
              )}
              {((visitStep !== 1 && visit) || (visitStep !== 0 && !visit)) && (
                <button
                  className="col-span-4 mt-12 font-semibold"
                  onClick={() => handlePrevious()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Go back
                </button>
              )}
              {((visitStep !== 0 &&
                visitStep !== finalStep &&
                visitStep !== 1 &&
                visitStep !== 2 &&
                visitStep !== 3 &&
                visitStep !== 4 &&  
                !visit) ||
                (visitStep !== 0 &&
                  visitStep !== 2 &&
                  visitStep !== 3 &&
                  visit) ||
                (visitStep === 1 && step1IsDirty && step1IsValid) ||
                (visitStep === 2 && productTypesIsDirty && productTypesIsValid) ||
                (visitStep === 3 && stakeholderEventIsDirty && stakeholderEventIsValid) ||
                (site?.type === 'site' && visitStep === 4 && siteConsumptionIsDirty && siteConsumptionIsValid) ||
                (site?.type === 'blockmaker' && visitStep === 4 && blockmakerConsumptionIsDirty && blockmakerConsumptionIsValid)) && (
                <button
                  className="col-span-4 col-start-5 text-right mt-12 font-semibold"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next step <FontAwesomeIcon icon={faAngleRight} />
                </button>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Card>Site not found!</Card>
      )}
    </Content>
  );
};

export default SiteVisit;
