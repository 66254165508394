import {
  addDoc,
  collection,
  deleteField,
  doc,
  FieldValue,
  updateDoc,
} from 'firebase/firestore';
import { CreateSiteData, SiteChanges, SiteStatus } from '../../../../global';
import { firestore } from '../utils/firebase';

type UpdateSiteData = {
  active?: boolean;
  status?: SiteStatus;
  changes?: SiteChanges | FieldValue;
  name?: string;
  primaryDistributor?: {
    id: string;
    name: string;
    phone: string;
  };
  primaryFSA?: {
    id: string;
    name: string;
    phone: string;
  };
  rejectionComment?: string;
  deletionRequested?: boolean | FieldValue;
  deletionRequestedBy?: {
    id: string;
    name: string;
    phone: string;
  };
  type?: string;
};

export const updateSite = async (siteId: string, data: UpdateSiteData) => {
  const ref = doc(firestore, 'sites', siteId);
  await updateDoc(ref, data);
};

export const createSite = async (data: CreateSiteData) => {
  try {
    await addDoc(collection(firestore, 'sites'), data);
  } catch (err) {
    console.error(err);
  }
};

export const confirmSiteRegistration = async (id: string) => {
  await updateSite(id, { status: 'confirmed', active: true });
};

export const rejectSiteRegistration = async (id: string, comment: string) => {
  await updateSite(id, { status: 'rejected', rejectionComment: comment });
};

export const confirmSiteRejection = async (id: string) => {
  await updateSite(id, { active: false, status: 'rejectionConfirmed' });
};

export const registerSiteChanges = async (
  id: string,
  force: boolean,
  changes: SiteChanges
) => {
  if (force) {
    await updateSite(id, {
      status: 'confirmed',
      ...changes,
      changes: deleteField(),
    });
  } else {
    const updateObj = {};
    Object.keys(changes).forEach(
      (key) => (updateObj[`changes.${key}`] = changes[key])
    );
    await updateSite(id, {
      status: 'changes',
      ...updateObj,
    });
  }
};

export const confirmSiteChanges = async (id: string, changes: SiteChanges) => {
  await updateSite(id, {
    status: 'confirmed',
    ...changes,
    changes: deleteField(),
  });
};

export const rejectSiteChanges = async (
  id: string,
  changes: SiteChanges,
  comment: string
) => {
  await updateSite(id, {
    rejectionComment: comment,
    status: 'changesRejected',
    changes: deleteField(),
  });
};

export const confirmSiteChangesRejection = async (id: string) => {
  await updateSite(id, {
    status: 'confirmed',
  });
};

export const requestSiteDeletion = async (
  id: string,
  requestedBy: { id: string; name: string, phone: string }
) => {
  await updateSite(id, {
    deletionRequested: true,
    deletionRequestedBy: requestedBy,
  });
};

export const cancelSiteDeletion = async (id: string) => {
  await updateSite(id, { deletionRequested: deleteField() });
};
